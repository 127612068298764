import React, { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { IntlShape, useIntl } from 'react-intl';
import Box from '@fuww/library/src/Box';
import LinearProgress from '@fuww/library/src/ProgressBar';
import { OrderStatus } from '../../../lib/graphql/api/graphql';
import messages from '../../../lib/messages.mjs';

interface OrderStatusNotificationProperties {
  orderStatus: OrderStatus;
  handleNotificationDismissed: () => void;
}

const getNotificationConfiguration = (intl: IntlShape) => ({
  [OrderStatus.PAID]: {
    message: intl.formatMessage(messages.orderStatusSuccess),
    variant: 'success' as const,
  },
  [OrderStatus.NEW]: {
    message: intl.formatMessage(messages.orderStatusNew),
    variant: 'info' as const,
  },
  [OrderStatus.CANCELED]: {
    message: intl.formatMessage(messages.orderStatusCanceled),
    variant: 'warning' as const,
  },
  [OrderStatus.EXPIRED]: {
    message: intl.formatMessage(messages.orderStatusExpired),
    variant: 'warning' as const,
  },
  [OrderStatus.FAILED]: {
    message: intl.formatMessage(messages.orderStatusFailed),
    variant: 'error' as const,
  },
});

const OrderStatusNotification = ({
  orderStatus,
  handleNotificationDismissed,
}: OrderStatusNotificationProperties) => {
  const intl = useIntl();

  useEffect(() => {
    const notificationConfiguration = getNotificationConfiguration(intl);
    const configuration = notificationConfiguration[orderStatus];

    enqueueSnackbar(configuration.message, {
      variant: configuration.variant,
      autoHideDuration: 20_000,
      preventDuplicate: true,
      onClose: handleNotificationDismissed,
    });
  }, [orderStatus, intl, handleNotificationDismissed]);

  if (orderStatus === OrderStatus.NEW) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="900000"
      >
        <LinearProgress noPadding />
      </Box>
    );
  }

  return null;
};

export default OrderStatusNotification;
