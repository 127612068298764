import React from 'react';
import MUILinearProgress from '@mui/material/LinearProgress';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';

const ProgressBarWrapper = styled.div<{ noPadding?: boolean }>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '32px 0')};
`;

const linearProgressPropertyTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  value: PropTypes.number,
  valueBuffer: PropTypes.number,
  variant: PropTypes.oneOf(['buffer', 'determinate', 'indeterminate', 'query']),
  noPadding: PropTypes.bool,
};

type LinearProgressProperties = InferProps<
  typeof linearProgressPropertyTypes
> & {
  classes?: Record<string, string>;
  className?: string;
  color?: 'primary' | 'secondary';
  value?: number;
  valueBuffer?: number;
  variant?: 'buffer' | 'determinate' | 'indeterminate' | 'query';
  noPadding?: boolean;
};

const LinearProgress = ({
  classes = {},
  className = 'progress-bar',
  color = 'primary',
  value,
  valueBuffer,
  variant = 'indeterminate',
  noPadding,
}: LinearProgressProperties) => (
  <ProgressBarWrapper noPadding={noPadding}>
    <MUILinearProgress
      classes={classes}
      className={className}
      color={color}
      value={value}
      valueBuffer={valueBuffer}
      variant={variant}
    />
  </ProgressBarWrapper>
);

export default LinearProgress;
